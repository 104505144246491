import React, { useEffect, useState } from "react";
import { DNA } from "react-loader-spinner";

const facts = [
  `Rabbits can live for up to 12 years.`,
  `Rabbits are masters of hearing.`,
  `Rabbits communicate using a secret code.`,
  `Baby rabbits are called ‘kittens’.`,
  `Overgrown teeth are common in rabbits.`,
  `Rabbits need lots of space to stay healthy.`,
  `Rabbits are very clean.`,
  `Rabbits are very social.`,
  `Rabbits eat their own poo.`,
];

const randGen = () => Math.floor(Math.random() * 9);

const Loader = () => {
  const [curFact, setCurFact] = useState(facts[randGen()]);
  useEffect(() => {
    setInterval(() => {
      setCurFact(facts[randGen()]);
    }, 6000);
  }, []);
  return (
    <div className="dfjcac ws1">
      <div className="ws2"></div>
      <div className="overlay">
        <div className="dffdcjcac ld1">
          <div className="ld2">
            <DNA
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
          <div className="ld3">Do You Know? </div>
          <div className="ld4">{curFact}</div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
