import React, { useEffect, useState } from "react";
import IndividualQuestion from "./IndividualQuestion";
import { shuffle } from "../Helper";

const Questions = ({ qsns, resetGame }) => {
  const [aQ, setAq] = useState(0);
  const [score, curScore] = useState(0);
  const [gF, setgF] = useState(false);

  const showNextQuestion = (cS) => {
    curScore((pS) => pS + cS);
    if (aQ === qsns.length - 1) {
      setgF(true);
    } else {
      setAq((pC) => pC + 1);
    }
  };

  const onReset = () => {
    setAq(0);
    curScore(0);
    setgF(false);
    resetGame();
  };

  let msg =
    score > qsns.length / 2
      ? "Good Job Rabbit!"
      : "Can do better, Don't worry Rabbit, You've learnt something new.";

  let cQ = qsns[aQ];
  let optns = shuffle([...cQ.incorrect_answers, cQ.correct_answer]);

  return (
    <div className="dfjcac ws1">
      <div className="ws2"></div>
      <div className="overlay">
        <div className="qs1">
          <div className="qs2">
            <div
              className={gF ? "qs22DN" : "qs22 dfjcac"}
            >{`Question ${aQ} of ${qsns.length}`}</div>
            <div className="qs21 dfjcac">
              Score: <div className="dfjcac">{score}</div>
            </div>
          </div>
          {gF ? (
            <div className="qsnsAnswrd dffdcjcac">
              <div className="qsnsAnswrd1 dfjcac">
                Final Score: <div className="qsnsAnswrd2 dfjcac">{score}</div>
              </div>
              <div className="qsnsAnswrd3">{msg}</div>
              <div className="qsnsAnswrd4 dfjcac">
                <button onClick={onReset}>Restart</button>
              </div>
            </div>
          ) : (
            <div className="qs3">
              <IndividualQuestion
                optns={optns}
                qsn={cQ.question}
                cA={cQ.correct_answer}
                showNextQuestion={showNextQuestion}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questions;
