import React, { useState } from "react";
import Rb from "../assets/images/rb.jpeg";
import swal from "sweetalert";

const WelcomeScreen = ({ onStart, onStartSeries }) => {
  const [name, setName] = useState("");
  return (
    <div className="dfjcac ws1">
      <div className="ws2"></div>
      <div className="overlay">
        <div className="dffdcjcac wsc">
          <div className="wsc1">Welcome Rabbit!</div>
          <input
            className="wsc2"
            placeholder="Enter your name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
          <button
            className="wsc3"
            onClick={() => {
              let nm = name.trim();
              if (nm === "") {
                swal("Error", "Please enter your name.", "error");
              } else onStart(name);
            }}
          >
            Let's Start
          </button>
          <button className="wsc3" onClick={onStartSeries}>
            Watch Full House
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
