import axios from "axios";
import { apiUrl } from "./config";

const diffMap = {
  Easy: "easy",
  Medium: "medium",
  Hard: "hard",
};

const getQsns = async (uP) => {
  try {
    let apiUrl = `https://opentdb.com/api.php?amount=${uP.nQ}&category=${
      uP.catg
    }&difficulty=${diffMap[uP.d]}&type=multiple`;
    let res = await axios.get(apiUrl);
    res = res.data?.results || [];
    return shuffle(res);
  } catch (excp) {
    return [];
  }
};

const fetchQuestions = async (uP, cN) => {
  for (let i = 0; i < 5; i++) {
    let qsns = await getQsns(uP);
    if (qsns.length > 0) return qsns;
  }
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export { shuffle, fetchQuestions };
