import React, { useState } from "react";
import { shuffle } from "../Helper";
import swal from "sweetalert";

const IndividualQuestion = ({ qsn, optns, cA, showNextQuestion }) => {
  const [dC, setDc] = useState(false);
  const [selAns, setSelAns] = useState("");
  const [finSel, setFinSel] = useState("");

  const onAnsCheck = (a) => {
    if (selAns === "") {
      swal("Error", "Please answer to check", "error");
      return;
    }
    setDc(true);
    setFinSel(selAns);
  };

  const onNext = () => {
    setDc(false);
    setSelAns("");
    setFinSel("");
    showNextQuestion(finSel == cA ? 1 : 0);
  };

  const decClass = (op) => {
    if (finSel == "" && selAns == "") return "dfjcac";
    if (finSel == "" && selAns != "") {
      if (selAns == op) return "dfjcac iqsnTA";
      else return "dfjcac";
    }
    if (finSel != "") {
      if (finSel == cA && cA == op) return "dfjcac iqsnCA";
      else if (cA == op) return "dfjcac iqsnCA";
      else if (selAns == op) return "dfjcac iqsnWA";
      else return "dfjcac";
    }
  };

  const dO = finSel !== "";

  return (
    <div className="iqsn1">
      <div
        className="iqsn2 dfjcac"
        dangerouslySetInnerHTML={{ __html: qsn }}
      ></div>
      <div className="iqsn3">
        <div className="iqsn5 dfjcac">
          <button
            className={decClass(optns[0])}
            onClick={() => {
              setSelAns(optns[0]);
            }}
            disabled={dO}
            dangerouslySetInnerHTML={{ __html: optns[0] }}
          ></button>
          <button
            className={decClass(optns[1])}
            onClick={() => {
              setSelAns(optns[1]);
            }}
            disabled={dO}
            dangerouslySetInnerHTML={{ __html: optns[1] }}
          ></button>
        </div>
        <div className="iqsn5 dfjcac">
          <button
            className={decClass(optns[2])}
            onClick={() => {
              setSelAns(optns[2]);
            }}
            disabled={dO}
            dangerouslySetInnerHTML={{ __html: optns[2] }}
          ></button>
          <button
            className={decClass(optns[3])}
            onClick={() => {
              setSelAns(optns[3]);
            }}
            disabled={dO}
            dangerouslySetInnerHTML={{ __html: optns[3] }}
          ></button>
        </div>
      </div>
      <div className="iqsn4 dfjcac">
        <button onClick={onAnsCheck} disabled={dC} className="iqsnbtnc">
          Check
        </button>
        <button disabled={!dC} className="iqsnbtnn" onClick={onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default IndividualQuestion;
