import React, { useState } from "react";
import Categories from "./Category.json";

const noOfQ = [10, 20, 30, 40, 50];
const difficulty = ["Easy", "Medium", "Hard"];
const players = ["Single Player", "Multi Player"];

const UserPreferenceScreen = ({ onPrefSet }) => {
  const [selCat, setSelCat] = useState(9);
  const [nQ, setNQ] = useState(10);
  const [dfc, setDfc] = useState("Easy");
  const [plyr, setPlyr] = useState("Single Player");

  const header = (hdr) => {
    return <div className="up3">{hdr}</div>;
  };

  const renderCatgOptions = () => {
    return Categories.map((e, idx) => {
      return (
        <option key={e.id} value={e.id}>
          {e.name}
        </option>
      );
    });
  };

  return (
    <div className="dfjcac ws1">
      <div className="ws2"></div>
      <div className="overlay">
        <div className="dffdcjcac up1">
          <div className="up2">
            {header("No Of Questions?")}
            <div className="up4">
              {noOfQ.map((n, idx) => {
                return (
                  <div
                    key={`nQ-${n}-${idx}`}
                    className={nQ == n ? "selDiv" : ""}
                    onClick={() => {
                      setNQ(n);
                    }}
                  >
                    {n}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="up2">
            {header("Difficulty?")}
            <div className="up4">
              {difficulty.map((n, idx) => {
                return (
                  <div
                    key={`dfc-${n}-${idx}`}
                    className={dfc == n ? "selDiv" : ""}
                    onClick={() => {
                      setDfc(n);
                    }}
                  >
                    {n}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="up2">
            {header("Category?")}
            <div className="up4">
              <select
                value={selCat}
                className="up5"
                onChange={(e) => {
                  setSelCat(e.target.value);
                }}
              >
                {renderCatgOptions()}
              </select>
            </div>
          </div>
          <div className="up2">
            {header("Single/Multi Player?")}
            <div className="up4">
              {players.map((n, idx) => {
                return (
                  <div
                    key={`plyr-${n}-${idx}`}
                    className={plyr == n ? "selDiv" : ""}
                    onClick={() => {
                      setPlyr(n);
                    }}
                  >
                    {n}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="up2">
            <div className="up6">
              <button
                className="wsc3"
                onClick={() => {
                  let pO = {
                    nQ,
                    plyr,
                    d: dfc,
                    catg: selCat,
                  };
                  onPrefSet(pO);
                }}
              >
                Let's Play
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreferenceScreen;
