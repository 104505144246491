import React, { useEffect, useState } from "react";
import cls from "./index.module.css";
import allEpisodes from "./episodes.json";
import { IoClose } from "react-icons/io5";
import back from "./back.png";

const SeriesList = () => {
  const [showVidPopup, setShowVidPopup] = useState(false);
  const [curSelSeason, setCurSelSeason] = useState({});
  const [curSelEpisode, setCurSelEpisode] = useState({});
  const [showSeries, setShowSeries] = useState(true);

  useEffect(() => {}, []);

  const onDoneWatching = () => {
    let allWatched = localStorage.getItem("episodesWatched") || {};
    try {
      allWatched = JSON.parse(allWatched);
    } catch (excp) {
      allWatched = {};
      console.log(`excp-------> `, excp);
    }
    let key = `${curSelEpisode.season}-${curSelEpisode.episodeNo}`;
    allWatched[key] = true;
    localStorage.setItem("episodesWatched", JSON.stringify(allWatched));
    setShowVidPopup(false);
  };

  const renderAllEpisodes = () => {
    let jsx = [];
    let allWatched = localStorage.getItem("episodesWatched") || {};
    try {
      allWatched = JSON.parse(allWatched);
    } catch (excp) {
      allWatched = {};
      console.log(`excp-------> `, excp);
    }
    let cSeason = curSelSeason.season;
    for (let i = 0; i < allEpisodes.length; i++) {
      let { name, season, episodeNo } = allEpisodes[i];
      if (cSeason != season) continue;
      let checkKey = `${season}-${episodeNo}`;
      jsx.push(
        <div
          className={allWatched[checkKey] ? `${cls.sLC3W}` : `${cls.sLC3}`}
          key={`episode-${season}-${episodeNo}`}
        >
          <div className={cls.sLC4}>
            <div className={cls.sLC41}>{`Se - ${season}`}</div>
            <div className={cls.sLC42}>{`Ep - ${episodeNo}`}</div>
          </div>
          <div className={cls.sLC5}>
            <button
              onClick={() => {
                setCurSelEpisode(allEpisodes[i]);
                setShowVidPopup(true);
              }}
            >
              watch
            </button>
          </div>
        </div>
      );
    }
    return jsx;
  };

  const renderAllSeries = () => {
    let jsx = [];
    let allSeasons = [
      { season: "1", tEpsds: 24 },
      { season: "2", tEpsds: 22 },
      { season: "3", tEpsds: 24 },
      { season: "4", tEpsds: 26 },
      { season: "5", tEpsds: 26 },
      { season: "6", tEpsds: 24 },
      { season: "7", tEpsds: 24 },
      { season: "8", tEpsds: 24 },
    ];

    let allWatched = localStorage.getItem("episodesWatched") || {};
    let aWBS = {};
    try {
      allWatched = JSON.parse(allWatched);
    } catch (excp) {
      allWatched = {};
      console.log(`excp(renderAllSeries)-------> `, excp);
    }

    for (let i in allWatched) {
      let sE = i.split("-")[0];
      if (!aWBS[sE]) aWBS[sE] = 0;
      aWBS[sE]++;
    }

    for (let i = 0; i < allSeasons.length; i++) {
      let { season, tEpsds } = allSeasons[i];
      jsx.push(
        <div
          className={tEpsds <= aWBS[season] ? `${cls.sLC3W}` : `${cls.sLC3}`}
          key={`Series-${season}`}
        >
          <div className={cls.sLC4}>
            <div className={cls.sLC41}>{`Se - ${season} (${
              aWBS[season] || 0
            }/${tEpsds})`}</div>
          </div>
          <div className={cls.sLC5}>
            <button
              onClick={() => {
                setCurSelSeason(allSeasons[i]);
                setShowSeries(false);
              }}
            >
              {`watch`}
            </button>
          </div>
        </div>
      );
    }
    return jsx;
  };

  return (
    <div className={cls.sLC1}>
      {showSeries ? (
        <>
          <div className={cls.ssLC2}> {renderAllSeries()}</div>
        </>
      ) : (
        <>
          <div className={cls.sLC2}>
            <div className={cls.sLC2Back}>
              <img
                src={back}
                className={cls.sLC2BackIcn}
                onClick={() => {
                  setShowSeries(true);
                  setCurSelSeason({});
                  setCurSelEpisode({});
                  setShowVidPopup(false);
                }}
              />
            </div>
            <div className={cls.sLC2}> {renderAllEpisodes()}</div>
          </div>
        </>
      )}
      {showVidPopup && (
        <div className={cls.sLC6}>
          <div
            className={cls.sLC10}
            onClick={() => {
              setShowVidPopup(false);
            }}
          >
            <IoClose className={cls.sLC10Icn} />
          </div>
          <div className={cls.sLC7}>
            <video className={cls.sLC9} controls>
              <source
                src={`https://fullhouseseries.s3.ap-south-2.amazonaws.com/${curSelEpisode.name}`}
                type="video/mp4"
              ></source>
            </video>
          </div>
          <div className={cls.sLC8}>
            <button onClick={onDoneWatching}>Done watching!</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeriesList;
