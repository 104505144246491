import { useState } from "react";
import "./App.css";
import "./Components/styles.css";
import Div100vh from "react-div-100vh";
import Loader from "./Components/Loader";
import { fetchQuestions } from "./Helper";
import Questions from "./Components/Questions";
import SeriesList from "./Components/Series/SeriesList";
import WelcomeScreen from "./Components/WelcomeScreen";
import UserPreferenceScreen from "./Components/UserPreferenceScreen";

function App() {
  const [curState, setCurState] = useState("Ws");
  const [curUserName, setCurUserName] = useState("");
  const [questions, setQuestions] = useState([]);

  const onStartSeries = () => {
    setCurState("Sl");
  };

  const onStart = (name) => {
    setCurUserName(name);
    setCurState("Up");
  };

  const onPrefSet = async (uP) => {
    setCurState("Ld");
    let qS = await fetchQuestions(uP, curUserName);
    setQuestions(qS);
    setCurState("Qs");
  };

  const resetGame = () => {
    setCurState("Up");
  };

  const renderBasedOnCurState = () => {
    switch (curState) {
      case "Ws":
        return (
          <WelcomeScreen onStart={onStart} onStartSeries={onStartSeries} />
        );
      case "Up":
        return <UserPreferenceScreen onPrefSet={onPrefSet} />;
      case "Ld":
        return <Loader />;
      case "Qs":
        return <Questions qsns={questions} resetGame={resetGame} />;
      case "Sl":
        return <SeriesList />;
    }
  };
  return <Div100vh>{renderBasedOnCurState()}</Div100vh>;
}

export default App;
